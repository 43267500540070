<style lang="less" scoped>
.person-center {
  width: 100%;
  height: 100%;
  background: #070911;
  padding: 160px 0;
  color: #dee6ff;
  &-wrapper {
    max-width: 1112px;
    margin: 0 auto;
    &-selfInfo {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding-bottom: 28px;
      background: #0f1118;
      &-title {
        width: 100%;
        padding-top: 28px;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        margin-left: 5%;
        font-size: 20px;
        // line-height: 40px;
        &-left {
          width: 4px;
          height: 20px;
          background: rgba(85, 119, 224, 1);
          border-radius: 5px;
        }
        &-right {
          font-size: 18px;
          font-weight: 400;
          color: rgba(85, 119, 224, 1);
          margin-left: 10px;
        }
      }
      &-item {
        width: 90%;
        margin-top: 10px;
        margin-left: 5%;
        font-size: 14px;
        font-family: "PingFangSC-Regular", "PingFang SC";
        font-weight: 400;
        color: rgba(222, 230, 255, 1);
        line-height: 24px;
      }
      &-item-flex {
        display: flex;
        line-height: 30px;
        margin-top: 20px;
        p {
          margin: 0;
        }
        &-left {
          width: 10.6%;
          p.person-center-wrapper-selfInfo-item-flex-left-main::before {
            content: " · ";
          }
        }
        &-right {
          width: 89%;
          text-align: left;
          margin-left: 1px;
          display: flex;
          .person-center-wrapper-selfInfo-item-flex-right-item {
            width: 100px;
            height: 100px;
            background: rgba(15, 17, 24, 1);
            border: 1px solid rgba(222, 230, 255, 0.3);
            text-align: center;
            line-height: 100px;
            margin-left: 10px;
          }
        }
      }
      &-container {
        margin-top: 20px;
      }
      &-it {
        display: flex;
        justify-content: space-between;
        background: #070911;
        font-size: 14px;
        padding-bottom: 0px;
        &-item {
          width: 45.5%;
          padding: 20px;
          background: #0f1118;
        }
      }
    }
  }
}
</style>

<template>
  <div class="person-center">
    <div class="person-center-wrapper">
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            园区信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              园区名称
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p> 南翔智地企业总部园 </p>
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              园区简介
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>
              园区总规划面积1.1平方公里，建筑面积115万平方米，入驻企业1800多家，兴业人员近万人，拥有2个市级孵化器，各类众创空间10多个。
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            赛事信息
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              项目征集
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>
              拟入驻企业/项目计划书
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            奖项设置
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              孵化基金
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>
              孵化基金（20-100）万元/项目,总计拟1000万元
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            材料提交
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              项目计划书
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p>
              至少包含如下内容：1.企业/项目简介；2.核心团队；3.产品（服务）；4.市场分析；5.财务预测；6.风险分析。
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-title">
          <div class="person-center-wrapper-selfInfo-title-left">
          </div>
          <div class="person-center-wrapper-selfInfo-title-right">
            园区优惠政策介绍
          </div>
        </div>
        <div class="person-center-wrapper-selfInfo-item person-center-wrapper-selfInfo-item-flex">
          <div class="person-center-wrapper-selfInfo-item-flex-left">
            <p class="person-center-wrapper-selfInfo-item-flex-left-main">
              园区扶持政策
            </p>
          </div>
          <div class="person-center-wrapper-selfInfo-item-flex-right">
            <p :style="{color: '#F9622F'}">
              注：项目获奖入选后，必须在南翔智地企业总部园注册企业，才能获得孵化基金支持
            </p>
          </div>
        </div>
      </div>
      <div class="person-center-wrapper-selfInfo">
        <div class="person-center-wrapper-selfInfo-item">
          <br />
          <p>
            附件：南翔智地内、外资扶持政策
          </p>
          <p>
            一、税收返税扶持：（一般企业）
          </p>
          <p>
            税收10~30W 返增值税8% 企业所得税5% ；<br />
            税收100~300W 返增值税15% 企业所得税10% ；<br />
            税收1000W以上 返增值税18% 企业所得税12%<br />
            注：文信类企业可享受两免三减半税收扶持政策（增值税30%、企业所得税18%）。优质企业可以一事一议。
          </p>
          <p>
            二、专项扶持：
          </p>
          <p>
            1，为企业提供免费一条龙注册服务，如需租赁园区办公场地，可享受租金优惠。2. 园区资源共享：如需举办活动，大礼堂、群团服务站、我家书房、艺术馆等资源空间可提供给使用。<br />
            2，千人计划：协助企业申请上海市千人计、创新创业政策。<br />
            3，住房政策：嘉定区优秀人才购房货币化补贴、租房补贴；创客客栈补贴；公租房申请指导。<br />
            4，资金扶持：a.建设银行无抵押无担保贷款200万（满足银行的贷款条件）。b.南翔创投股权直投300万左右（满足创投的尽调）。<br />
            5，协助指导企业申请各项产业认定申报工作。(例：四大产业认定、高新技术企业认定、科创券申报、研究费用加计扣除、专利费用资助申请等)<br />
            6，企业落户南翔后，乙方股东或法人如符合嘉定人才落户的相关条件，园区将协助乙方办理相关手续。<br />
            7，企业落户南翔相关公司年税收达到3000万后，园区协助企业申请总部大楼。9.如果企业需要上新三板或股交中心，可以申请嘉定区上市挂牌费用补贴，最新扶持资 金为100万，如有变动以嘉定区最新扶持政策为准。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'enter',
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  components: {
  }
}
</script>
